body {
  margin: 10px;
  background-color: #222;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact;

  @media print {
    background-color: #fff;
    margin: 0;
  }
}

main {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  background-color: #fff;
  font-size: 12pt;
  // font-family: 'AT Surt';
  // font-family: 'Bandeins Sans';
  // font-family: 'Basier Circle';
  // font-family: 'Chalet Book';
  font-family: 'Gilroy';
  font-weight: 600;

  h1 {
    font-size: 24pt;
    margin: 0 0 4mm 0;
  }

  h2 {
    font-size: 18pt;
    margin: 0 0 2mm 0;
  }

  p, ul, ol {
    margin: 0 0 2mm 0;
  }

  header {
    background-color: #ddd;
    padding: 1cm;
    display: grid;
    grid-template-columns: 1fr 8mm 1.618fr 1.618fr;
    grid-template-rows: auto auto;
    grid-template-areas: 'photo g perso situ' 'photo g desc  desc';


    @media print {
      background-color: #eee;
      margin: 0;
    }

    .photo {
      grid-area: photo;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .perso {
      grid-area: perso;
    }

    .situ {
      grid-area: situ;
    }

    .desc {
      grid-area: desc;
      margin-top: auto;

      p {
        margin: 0;
        padding-right: 7mm;
      }
    }
  }

  .content {
    padding: 1cm;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8mm;
    font-size: 14pt;

    .benevolat {
      ul {
        padding-left: 0;
        list-style: none;

        li {
          margin-bottom: 6pt;
        }
      }
    }

    .competences, .loisirs {
      ul {
        columns: 2;
        column-gap: 4mm;
      }
    }

    > div > section {
      margin: 0 0 16mm 0;

      > ul {
        padding: 0;
        list-style: none;

        li {
          break-inside: avoid-column;

          .date {
            margin: 0;
            opacity: 0.3;
            font-size: 10pt;
          }

          ul {
            padding-left: 0;
            list-style: none;
            columns: 2;
            column-gap: 6mm;
      break-inside: avoid-column;

            li {
              margin-bottom: 6pt;
              font-size: 12pt;
            }
          }
        }
      }
    }
  }
}
