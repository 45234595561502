body {
  margin: 10px;
  background-color: #222;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact;
}
@media print {
  body {
    background-color: #fff;
    margin: 0;
  }
}

main {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  background-color: #fff;
  font-size: 12pt;
  font-family: "Gilroy";
  font-weight: 600;
}
main h1 {
  font-size: 24pt;
  margin: 0 0 4mm 0;
}
main h2 {
  font-size: 18pt;
  margin: 0 0 2mm 0;
}
main p, main ul, main ol {
  margin: 0 0 2mm 0;
}
main header {
  background-color: #ddd;
  padding: 1cm;
  display: grid;
  grid-template-columns: 1fr 8mm 1.618fr 1.618fr;
  grid-template-rows: auto auto;
  grid-template-areas: "photo g perso situ" "photo g desc  desc";
}
@media print {
  main header {
    background-color: #eee;
    margin: 0;
  }
}
main header .photo {
  grid-area: photo;
}
main header .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
main header .perso {
  grid-area: perso;
}
main header .situ {
  grid-area: situ;
}
main header .desc {
  grid-area: desc;
  margin-top: auto;
}
main header .desc p {
  margin: 0;
  padding-right: 7mm;
}
main .content {
  padding: 1cm;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8mm;
  font-size: 14pt;
}
main .content .benevolat ul {
  padding-left: 0;
  list-style: none;
}
main .content .benevolat ul li {
  margin-bottom: 6pt;
}
main .content .competences ul, main .content .loisirs ul {
  columns: 2;
  column-gap: 4mm;
}
main .content > div > section {
  margin: 0 0 16mm 0;
}
main .content > div > section > ul {
  padding: 0;
  list-style: none;
}
main .content > div > section > ul li {
  break-inside: avoid-column;
}
main .content > div > section > ul li .date {
  margin: 0;
  opacity: 0.3;
  font-size: 10pt;
}
main .content > div > section > ul li ul {
  padding-left: 0;
  list-style: none;
  columns: 2;
  column-gap: 6mm;
  break-inside: avoid-column;
}
main .content > div > section > ul li ul li {
  margin-bottom: 6pt;
  font-size: 12pt;
}
/*# sourceMappingURL=index.1c9b261a.css.map */
